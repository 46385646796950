.ds-container {
    // width: 100%;
    height: 100%;
}
.timerContainer {
    padding: 2rem 1rem 0 0 !important;
}
.not-configured-container .container {
    margin: unset !important;
    gap: 35px !important;
}